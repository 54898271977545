<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="0" md="3"></v-col>
                        <v-col cols="12" md="6" class="my-3">
                            <v-col cols="12" class="reservation-select-title mt-6">
                                예약자 정보
                            </v-col>
                            <!--<table class="readTable ">
                                <colgroup>
                                    <col style="width:30%"/>
                                    <col style="width:70%"/>
                                </colgroup>
                                <tr>
                                    <th class="th">예약자명</th>
                                    <td class="td">
                                        <input id="resvNm" v-model="resvConfirmInfo.resvName"
                                               placeholder="정확히 입력해주세요.">
                                    </td>
                                </tr>
                                <tr v-if="payInfo.isPw">
                                    <th class="th">비밀번호</th>
                                    <td class="td">
                                        <input type="password" id="resvPw" v-model="resvConfirmInfo.resvPw"
                                               style="width: 100%">
                                    </td>
                                </tr>
                                <tr>
                                    <th class="th">휴대전화</th>
                                    <td class="td-phone" style="display: flex;">
                                        <select v-model="phone_tmp.phone1" @change="selectChange1($event)"
                                                style="margin:3px;width: 110px;">
                                            <option value="010">010</option>
                                            <option value="direct">직접입력</option>
                                        </select>
                                        <input name="phone1" v-model="phone_tmp.phone11" placeholder="010"
                                               class="phoneNum"
                                               style="width: 50px;" maxlength="3"
                                               v-if="isDirectPhone"
                                        > -
                                        <input name="phone2" v-model="phone_tmp.phone2" placeholder="0000"
                                               style="width: 80px;" maxlength="4"
                                        > -
                                        <input name="phone3" v-model="phone_tmp.phone3" placeholder="0000"
                                               style="width: 80px;" maxlength="4">
                                    </td>
                                </tr>
                            </table>-->

                            <table class="table-left-s mobile">
                                <colgroup>
                                    <col style="width:35%"/>
                                    <col style="width:65%"/>
                                </colgroup>
                                <tr>
                                    <th class="th">예약자명</th>
                                    <td class="td">
                                        <input id="resvNm" v-model="resvConfirmInfo.resvName"
                                               placeholder="정확히 입력해주세요.">
                                    </td>
                                </tr>
                                <tr v-if="payInfo.isPw">
                                    <th class="th">비밀번호</th>
                                    <td class="td">
                                        <input type="password" id="resvPw" v-model="resvConfirmInfo.resvPw"
                                               style="width: 100%">
                                    </td>
                                </tr>
                                <tr>
                                    <th class="th">휴대전화</th>
                                    <td class="td-phone">
                                        <input v-model="resvConfirmInfo.resvPhone" style="width: 100%;"
                                               placeholder="- 없이 숫자만 입력"
                                               maxlength="13"
                                               @keyup="autoHyphen(resvConfirmInfo.resvPhone)">
                                        <!--<select v-model="phone_tmp.phone1" @change="selectChange1($event)"
                                                style="margin:3px;width: 70px;">
                                            <option value="010">010</option>
                                            <option value="direct">직접입력</option>
                                        </select>
                                        <input name="phone1" v-model="phone_tmp.phone11" placeholder="010"
                                               class="phoneNum"
                                               maxlength="3"
                                               v-if="isDirectPhone"
                                        > -
                                        <input name="phone2" v-model="phone_tmp.phone2" placeholder="0000"
                                                maxlength="4"
                                        > -
                                        <input name="phone3" v-model="phone_tmp.phone3" placeholder="0000"
                                               maxlength="4">-->
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <template v-if="!isMobile">
                                <ul style="" class="roundBtn-blue calc-3">
                                    <li></li>

                                    <li>
                                        <a @click="revConfirmBtnClick(true)">조회하기</a>
                                    </li>
                                    <li></li>
                                </ul>
                            </template>
                            <template v-else>
                                <ul style="" class="roundBtn-blue calc-1">
                                    <li>
                                        <a @click="revConfirmBtnClick(true)">조회하기</a>
                                    </li>
                                </ul>
                            </template>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'ReservationSelect',
        components: {},
        data: () => ({
            resvConfirmInfo: {
                resvName: "",
                resvPw: "",
                resvPhone: "",
                resvStartDate: "",
                isPw: false,

                phone1:"010",

            },
            isDirectPhone: false,
            phone_tmp: {
                phone1: "010",
                phone11: "",
                phone2: "",
                phone3: ""
            },
            payInfo:{
                pgMethod: {
                    isMethod0: false,
                    isMethod1: false,
                    isMethod2: false,
                },
                pgNm: "",
                pgId: "",
                isPw: false,
            },
            isMobile: false,

        }),
        created: function () {

            this.isMobile = this.$isMobile();

            // 맨위로
            window.scrollTo(0,0);
            this.$store.commit("resvConfirm/CLEAR_RESV_USR_INFO");
            this.init();
        },
        methods: {
            init(){
                this.$store.dispatch("admin/getComn", {comnGubun: 'payInfo'})
                    .then((resp) => {
                        setTimeout(() => {

                            if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                this.payInfo = JSON.parse(resp.message.comnCont);
                            }

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            revConfirmBtnClick(val) {

                if (val) {

                    if (this.resvConfirmInfo.resvName.length === 0) {
                        alert("예약자명을 입력해 주세요.");
                        return;
                    }

                    this.resvConfirmInfo.isPw = this.payInfo.isPw;

                    if(this.payInfo.isPw){
                        if (this.resvConfirmInfo.resvPw.length === 0) {
                            alert("비밀번호를 입력해 주세요.");
                            return;
                        }
                    }else{
                        this.resvConfirmInfo.resvPw = "";
                    }

                    if (this.resvConfirmInfo.resvPhone === undefined || this.resvConfirmInfo.resvPhone.length === 0) {
                        alert("전화번호를 입력해 주세요.");
                        return;
                    } else {

                        if(!this.$isHpFormatH(this.resvConfirmInfo.resvPhone)){
                            alert("전화번호를 정확히 입력해 주세요.");
                            return;
                        }else{
                            // 핸드폰 번호 변경
                            this.resvConfirmInfo.resvPhone = this.$delPhoneHyphen(this.resvConfirmInfo.resvPhone);

                        }
                    }


                    /*if (this.isDirectPhone) {

                        if (this.phone_tmp.phone11.length === 0 || this.phone_tmp.phone2.length === 0 || this.phone_tmp.phone3.length === 0) {
                            alert("전화번호 입력해 주세요.");
                            return;
                        }else{
                            this.resvConfirmInfo.resvPhone = this.phone_tmp.phone11 + "-" + this.phone_tmp.phone2 + "-" + this.phone_tmp.phone3;
                        }
                    } else {

                        if (this.phone_tmp.phone1.length === 0 || this.phone_tmp.phone2.length === 0 || this.phone_tmp.phone3.length === 0) {
                            alert("전화번호 입력해 주세요.");
                            return;
                        }else{
                            this.resvConfirmInfo.resvPhone = this.phone_tmp.phone1 + "-" + this.phone_tmp.phone2 + "-" + this.phone_tmp.phone3;
                        }
                    }*/


                    this.$store.commit("resvConfirm/SAVE_RESV_USR_INFO", this.resvConfirmInfo);
                    this.$router.push({path: "/reservation/resvConfirmList"});

                } else {

                }

            },
            selectChange1(event) {

                this.resvConfirmInfo.phone11 = "";

                if (event.target.value === 'direct') {
                    this.isDirectPhone = true

                } else {
                    this.isDirectPhone = false;
                }

            },
            autoHyphen(val) {

                if(this.resvConfirmInfo.resvPhone != undefined){
                    this.resvConfirmInfo.resvPhone = this.resvConfirmInfo.resvPhone
                        .replace(/[^0-9]/g, '')
                        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
                }

            },
        },
        mounted() {
            this.$nextTick(() => {
                var container = document.getElementsByClassName("td-phone")[0];
                container.onkeyup = function (e) {
                    var target = e.srcElement || e.target;
                    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
                    var myLength = target.value.length;
                    if (myLength >= maxLength) {
                        var next = target;
                        while (next = next.nextElementSibling) {
                            if (next == null)
                                break;
                            if (next.tagName.toLowerCase() === "input") {
                                next.focus();
                                break;
                            }
                        }
                    }
                    // Move to previous field if empty (user pressed backspace)
                    else if (myLength === 0) {
                        var previous = target;
                        while (previous = previous.previousElementSibling) {
                            if (previous == null)
                                break;
                            if (previous.tagName.toLowerCase() === "input") {
                                previous.focus();
                                break;
                            }
                        }
                    }
                }
            })
        },
        computed: {},
    }
</script>

<style>

</style>
